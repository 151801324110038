<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-30">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>mail_outline</md-icon>
          </div>
          <h4 class="title">Загрузка отчета</h4>
        </md-card-header>

        <md-card-content>
          <md-field>
            <label>Приложение</label>
            <md-select v-model="app" name="app2" id="app">
              <md-option
                v-for="(value, name) in apps"
                :value="name"
                v-bind:key="name"
                >{{ value }}</md-option
              >
            </md-select>
          </md-field>
          <md-field>
            <input
              class="md-input"
              type="file"
              id="file"
              ref="file"
              v-on:change="selectFile"
            />
          </md-field>
        </md-card-content>

        <md-card-actions md-alignment="left">
          <md-button class="md-success" v-on:click="SendFile">Submit</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <div class="md-layout-item md-size-50">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>mail_outline</md-icon>
          </div>
          <h4 class="title">Создание приложения</h4>
        </md-card-header>

        <md-card-content>
          <md-field>
            <label>Название</label>
            <md-input v-model="name" type="text"></md-input>
          </md-field>
          <md-field>
            <label>Evotor UUID</label>
            <md-input v-model="uuid" type="text"></md-input>
          </md-field>
        </md-card-content>

        <md-card-actions md-alignment="left">
          <md-button class="md-success" v-on:click="SaveApp"
            >Сохранить</md-button
          >
        </md-card-actions>
      </md-card>
    </div>
    <div class="md-layout-item md-size-30">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>mail_outline</md-icon>
          </div>
          <h4 class="title">Загрузка отчета EVOTOR</h4>
        </md-card-header>

        <md-card-content> </md-card-content>

        <md-card-actions md-alignment="left">
          <a
            href="https://report.aki1.ru/upload.html"
            class="md-button md-success"
            style="
              width: 170px;
              height: 40px;
              padding-top: 10px;
              padding-left: 5px;
            "
            >Загрузка отчета EVOTOR</a
          >
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;

// vm.$on('md-selected', function (msg) {
//   console.log(msg)
// })

export default {
  name: "EvoLoadReport",
  data: () => ({
    app: "",
    name: "",
    uuid: "",
    report: null,
    apps: {},
  }),
  mounted() {
    let data = {};
    data.token = localStorage.token;
    axios({
      method: "POST",
      url: "https://report.aki1.ru/select_app.php",
      timeout: 1000,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response);
        if (response.status == 250) {
          this.$router.push({ name: "CuLogin" });
        }
        if (response.status == 200) {
          this.apps = response.data;
        }
      })
      .catch((error) => {
        this.notific("danger", "что то пошло не так " + error);
      });
  },
  methods: {
    selectFile() {
      this.report = this.$refs.file.files[0];
    },
    SendFile() {
      this.notific("info", "Отправляю файлик, жди ...");
      let formData = new FormData();
      // console.log(this.report);
      formData.append("file", this.report);
      formData.append("token", localStorage.token);
      formData.append("app_uuid", this.app);
      axios
        .post("https://report.aki1.ru/recieve_file.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((r) => {
          if (r.status == 250) {
            this.$router.push({ name: "CuLogin" });
          }
          if (r.status == 200) {
            this.notific("success", "Файл загружен ");
          }
          // console.log(r);
        })
        .catch((e) => {
          this.notific("danger", "что то пошло не так " + error);
          // console.log(e);
        });
    },
    notific(type, text) {
      this.$notify({
        message: text,
        icon: "add_alert",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: type,
      });
    },
    SaveApp() {
      let data = {};
      data.name = this.name;
      data.uuid = this.uuid;
      data.token = localStorage.token;

      axios({
        method: "POST",
        url: "https://report.aki1.ru/saveapp.php",
        timeout: 1000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status == 250) {
            this.$router.push({ name: "CuLogin" });
          }
          if (response.status == 200) {
            this.notific("success", "Прила успешно сохранена.");
            this.name = "";
            this.uuid = "";
          }
        })
        .catch((error) => {
          this.notific("danger", "что то пошло не так " + error);
          // console.log(error.message);
          if (error.message == "Request failed with status code 450") {
            // console.log("test");
          }
        });
    },
  },
};
</script>

<style scoped></style>
