<template>
  <div class="md-layout">
    <modal v-if="smallAlertModal" @close="smallAlertModalHide">
      <template slot="header">
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="smallAlertModalHide"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <p>Запишите результат звонка.</p>
        <md-field class="md-form-group">
          <!--                    <label>Результат звонка</label>-->
          <md-input v-model="rezult" type="text"></md-input>
        </md-field>
      </template>

      <template slot="footer">
        <!--                <md-button class="md-danger md-simple" @click="CreateTicket('disconnect')">Недозвон</md-button>-->
        <!--                <md-button class="md-info md-simple" @click="CreateTicket('trial')">Помог</md-button>-->
        <md-button class="md-success md-simple" @click="CreateTicket('online')"
          >Сохранить</md-button
        >
        <md-button class="md-simple" @click="smallAlertModalHide"
          >Отмена</md-button
        >
      </template>
    </modal>
    <div>
      <!--            <md-button class="md-rose md-round small-alert-modal" >Small Alert Modal</md-button>-->
    </div>
    <div class="md-layout-item md-layout" v-if="not_select">
      <div
        class="md-layout-item"
        v-for="app in apps_status"
        v-bind:key="app[1].app"
        v-on:click="AppClick(app[1])"
      >
        <stats-card style="width: 200px; min-height: 250px">
          <template slot="header">
            <div class="my-card-img">
              <img :src="app_icon[app[1].app]" class="my_app_ico" />
            </div>
          </template>

          <template slot="footer">
            <h4 class="title">{{ app[1].name }}</h4>
          </template>
        </stats-card>
      </div>
    </div>
    <div class="md-layout-item md-layout md-size-100" v-if="!not_select">
      <div class="md-layout md-layout-item md-size-100">
        <div class="md-layout-item md-size-10">
          <md-button v-on:click="back">Назад</md-button>
        </div>
        <div class="md-layout-item md-xsmall-size-50" v-if="ClientSelected">
          <stats-card
            header-color="green"
            style="height: 115px; overflow: scroll"
          >
            <template slot="header">
              <p class="category">Всего устройств</p>
              <h3 class="title">
                <animated-number
                  :value="user_info.devicesCount"
                ></animated-number>
              </h3>
            </template>
          </stats-card>
        </div>
        <div class="md-layout-item md-xsmall-size-50" v-if="ClientSelected">
          <stats-card
            header-color="green"
            style="height: 115px; overflow: scroll"
          >
            <template slot="header">
              <p class="category">Регион</p>
              <h4 class="title">
                {{ user_info.region }}
              </h4>
            </template>
          </stats-card>
        </div>
        <div class="md-layout-item md-xsmall-size-50" v-if="ClientSelected">
          <stats-card
            header-color="green"
            style="height: 115px; overflow: scroll"
          >
            <template slot="header">
              <p class="category">email</p>
              <p class="md-scrollbar">
                {{ user_info.email }}
              </p>
            </template>
          </stats-card>
        </div>
        <div class="md-layout-item md-xsmall-size-50" v-if="ClientSelected">
          <stats-card
            header-color="green"
            style="height: 115px; overflow: scroll"
          >
            <template slot="header">
              <p class="category">Контактный телефон</p>
              <p>
                {{ user_info.contact_phones }}
              </p>
            </template>
          </stats-card>
        </div>
        <div class="md-layout-item md-xsmall-size-50" v-if="ClientSelected">
          <stats-card
            header-color="green"
            style="height: 115px; overflow: scroll"
          >
            <template slot="header">
              <p class="category">ЭВОТОР ЛК</p>
              <p>
                {{ user_info.lk }}
                <md-button @click="smallAlertModal = true" class="md-success"
                  >Позвонить</md-button
                >
              </p>
              <p></p>
            </template>
          </stats-card>
        </div>
      </div>
      <div
        class="
          md-layout-item
          md-xsmall-size-100
          md-medium-size-100
          md-sm-size-100
          md-size-30
        "
      >
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">Состояние подписки</h4>
          </md-card-header>
          <md-card-content>
            <md-table v-model="app_data" class="table-full-width">
              <md-table-row
                slot="md-table-row"
                slot-scope="{ item }"
                :class="getClass(item)"
                v-on:click="ClientClick(item)"
              >
                <md-table-cell md-label="STATUS">{{
                  item.status
                }}</md-table-cell>
                <md-table-cell md-label="UID">{{ item.uid }}</md-table-cell>
                <md-table-cell md-label="DATE">{{ item.ddate }} </md-table-cell>
                <md-table-cell md-label="call"
                  >{{ item.rezult }}
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
      <div
        class="md-layout-item md-xsmall-size-100 md-medium-size-100 md-size-70"
        v-if="ClientSelected"
      >
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <h3 class="title">Приложения клиента</h3>
          </md-card-header>
          <md-card-content>
            <div class="md-layout-item md-layout">
              <div
                v-for="app in user_apps"
                v-bind:key="app.app"
                style="width: 100px"
                v-on:click="ClientsAppClick(app.app)"
              >
                <img
                  :src="app_icon[app.app]"
                  style="width: 80px; height: 80px; margin-top: 15px"
                />
                <p>
                  <b>{{ app.status }}</b>
                </p>
              </div>
            </div>
          </md-card-content>
        </md-card>
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="md-layout-item md-layout">
              <div class="md-layout-item md-xsmall-size-50">
                <img
                  :src="app_icon[user_app_info.app]"
                  style="width: 80px; height: 80px; margin-top: 15px"
                />
              </div>
              <div class="md-layout-item md-xsmall-size-50">
                <!--                                <md-card header-color="green" style="height: 115px;overflow: scroll;">-->
                <!--                                    <md-card-header slot="header">-->
                <p class="title">Активно касс</p>
                <h4 class="title">
                  {{ user_app_info.devicesActiveCount }}
                </h4>
                <!--                                    </md-card-header>-->
                <!--                                </md-card>-->
              </div>
              <div class="md-layout-item md-layout md-xsmall-size-100">
                <div class="md-layout-item md-xsmall-size-50">
                  <span class="title"><b> Дата установки </b></span>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <span class="title">
                    {{ user_app_info.devicesActiveDate }}
                  </span>
                </div>
              </div>
              <div class="md-layout-item md-layout md-xsmall-size-100">
                <div class="md-layout-item md-xsmall-size-50">
                  <span class="title"><b> Последний платеж </b></span>
                </div>

                <div class="md-layout-item md-xsmall-size-50">
                  <span class="title"
                    ><b>
                      {{ user_app_info.lastPaymentDate }}
                    </b></span
                  >
                </div>
              </div>
              <div class="md-layout-item md-layout md-xsmall-size-100">
                <div class="md-layout-item md-xsmall-size-50">
                  <span class="title"><b> Следующий Платеж </b></span>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <span class="title">
                    {{ user_app_info.nextPaymentDate }}
                  </span>
                </div>
              </div>
              <div class="md-layout-item md-layout md-xsmall-size-100">
                <div class="md-layout-item md-xsmall-size-50">
                  <span class="title"><b> Тариф </b></span>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <span class="title">
                    {{ user_app_info.planName }}
                  </span>
                </div>
              </div>
              <div class="md-layout-item md-layout md-xsmall-size-100">
                <div class="md-layout-item md-xsmall-size-50">
                  <span class="title"><b> Устройства</b></span>
                </div>
                <div class="md-layout-item md-xsmall-size-50">
                  <span class="title">
                    {{ user_app_info.devicesActive }}
                  </span>
                </div>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <md-table v-model="user_app_data" class="table-full-width">
              <md-table-row
                slot="md-table-row"
                slot-scope="{ item }"
                :class="getClass(item)"
              >
                <md-table-cell md-label="">{{ item.chdate }}</md-table-cell>
                <md-table-cell md-label="статус">{{
                  item.status
                }}</md-table-cell>
                <md-table-cell md-label="устройств">{{
                  item.devices
                }}</md-table-cell>
                <md-table-cell md-label="событие">{{
                  item.hook
                }}</md-table-cell>
                <md-table-cell md-label="тариф">{{
                  item.planId
                }}</md-table-cell>
                <md-table-cell md-label="следующий платеж">{{
                  item.nextBillingDate
                }}</md-table-cell>
                <md-table-cell md-label="тест до"
                  >{{ item.trialPeriodDuration }}
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Buttons from "../../pages/Dashboard/Components/Buttons";
const axios = require("axios").default;
import { StatsCard, AnimatedNumber, Modal } from "@/components";

export default {
  name: "EvoReport",
  components: { StatsCard, AnimatedNumber, Modal },
  data() {
    return {
      rezult: "",
      smallAlertModal: false,
      ClientSelected: false,
      tk: "",
      user_info: { devicesCount: 0, email: "", contact_phones: "", lk: "" },
      user_apps: {},
      // user_selected: '',
      user_app_data: { devicesActiveDate: "" },
      user_app_info: {
        devicesActiveDate: "",
        nextPaymentDate: "",
        planName: "",
        devicesActive: "",
      },
      not_select: true,
      apps_status: [],
      selected_app: {},
      app_data: [],
      app_icon: {
        "d9644876-9073-4165-8c2c-0004eac05690":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/0fb52e9a-861f-466b-9139-19d2e6b6bcf6.png",
        "86e792db-1253-45c0-97d4-20b4c2ef97a3":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/52f330b0-6c16-4c79-a9d5-27210f95308e.png",
        "df81f61e-ce7c-4ff0-8889-98704e0011d6":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/dea947fa-dc3e-4fdc-80d1-2126e137f8de.png",
        "6a18b120-a3ba-41fe-9804-6c22658bbfc0":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/62e7be33-1e7b-4a20-870c-601920d3147b.png",
        "03faa7d5-e5da-47aa-9851-3e509205ff5f":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/0df919c4-fb12-43ed-9faf-a64f436aced9.png",
        "a094737d-d89c-4243-aaea-6b2bd8c96461":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/feaa32f7-11e6-47a1-8b63-1df0f9cbd9d1.png",
        "f82aa937-a44f-4e88-ba1f-981f60f7d875":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/100c8bcc-4cf6-41a5-a1a9-177f72ad91e3.jpeg",
        "b1110cfb-6583-4557-9223-1a3da3ee72c0":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/84122dde-5137-4929-a6ae-82c6d895dbea.png",
        "c6d23f5c-c467-4feb-b1f2-07aab06e63f7":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/ef2c858d-f954-4016-86e9-65b490def61e.png",
        "64fbc0d3-0ce5-464a-b9f4-f0339a793867":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/4144e951-ef3d-4b45-86e4-7fc869b615ab.jpg",
        "6663e115-016c-4ad2-a9fd-71900474b81e":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/e6ab7c8a-5c26-47d9-a077-9f18699a6ab7.png",
        "51f9fece-e90f-4a3b-bc31-eabea9606c6f":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/fe8ef66b-b02c-4238-bfd0-ca659b0256ad.png",
        "b60e2bc0-fe4b-413f-baf2-17def028bfab":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/75cea1f6-3866-4d74-8159-e256ceb0a022.png",
        "8e65c108-6cae-4465-99bb-75496336b4a6":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/58ec20f3-ee9a-4ce7-ad48-93cc41d54c91.png",
        "4059b2fe-4fad-4879-bab1-8ed5fdbce699":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/78c04b25-9693-4f73-8a7e-29f49ed0c42d.jpg",
        "8f9b5d0b-8c5f-4294-b2a5-82281177e6da":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/615d6418-030e-45f0-a024-4c2f1130b93c.jpg",
        "1c28ed2b-40fd-4c70-9368-ecfee556a3ed":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/20fd8ab4-927a-4166-bd17-f885732ff690.png",
        "7e4c799b-b89d-4a89-8edf-1d888153de2e":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/17e38a26-1acb-4be7-b811-3328142c667b.jpg",
        "a8438778-0f87-4d53-9fdf-88d8c70308a3":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/42f5604b-7ace-4742-b880-ca0dff4f9f71.png",
        "b282fe98-f481-4444-91e5-2cb2949a4aac":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/5fe76f3e-a2f0-4149-b219-b78aff1f1805.png",
        "4354f20f-6f1f-4328-bb67-2f507feecfdb":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/bb7b2fe4-3ef4-49d3-9651-919aab3f6e0f.jpg",
        "506b5022-d070-43af-a280-0f8cf522addf":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/1c7/1c_7.png",
        "c79feab0-c985-4830-85ff-c4a2102dc548":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/ccbaace1-78dc-4a42-8a23-c4f1108a4379.png",
        "aa74a2a3-a8ba-4abc-addf-b172ac88ce60":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/6878d628-9894-471c-a03b-632c9cfcdf30.jpg",
        "51b70ed7-4345-4ea0-90f1-95a7926737a7":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/04d05f15-7982-4178-b273-70703bf3997b.jpg",
        "00e073a0-a8dd-4c7f-80d5-45f30be43ae0":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/d743e5ce-d810-43ff-8f12-b61aec3bfc03.png",
        "f2082a7e-d52b-4137-a8d9-53244d9df10a":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/915848b0-7824-4e9d-b818-f640c835e225.jpg",
        "ff84eb1b-a445-4a8d-b3b0-46bfffd4741d":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/aa646d80-1e50-48b8-9546-4e14e2e2bc9a.png",
        "3d0304cd-145e-4a4b-8a9a-0ed783ded2d9":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/bed2db10-bbe3-4d30-bf7b-8f061a4bd3e9.png",
        "be94e2af-c59f-4645-8ccc-e42cbc185851":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/de7e3885-147c-4332-bcf5-9ab8a39a7497.png",
        "582b8195-446f-48c4-9229-0d0722a63e88":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/aa646d80-1e50-48b8-9546-4e14e2e2bc9a.png",
        "80b0261e-4f3b-4178-be3b-63557a1535b6":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/15e0f695-543e-426b-8a73-572db9c45521.png",
        "7c782b43-1f97-4c4c-9a66-d29c2bbc704e":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/9551a057-7239-412d-a0f0-7964b74f29cc.png",
        "3a7c4e6b-4867-4740-9c34-c54c72619625":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/aea3423d-197f-4d7a-944d-405ecfa39e9c.jpg",
        "2b6b3610-5f1d-49c5-a5f8-fe232ff108b6":
          "https://platformaofd.ru/static/icons/logo.svg",
        "799ac66d-1841-48ff-acc5-b59b18e2b8b8":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/cb946eb1-0888-4a40-9424-9ba27ecf702e.jpg",
        "11278faa-c9d3-4502-9ab4-842319d7ce05":
          "https://platformaofd.ru/static/icons/logo.svg",
        "89106b9d-215c-446a-ae8f-4675b5005bae":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/f0a2dd5d-d077-4247-aa13-c9f4f7e01b71.png",
        "426d3fe3-c05e-4ae1-b19e-8657c724f86b":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/32e4c6a2-4e89-4000-b27e-d2215efc41f4.png",
        "c5c4cef3-d7ef-4937-aa0f-4254f19e1646":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/4dff6a7b-53de-47c7-a8f0-f87f65163b36",
        "294218f7-347e-45de-8397-d308e1cbfd74":
          "https://el8afj1hku.a.trbcdn.net/static/catalog/acfca61b-6462-4f3e-b82a-4cba2805aa71",
      },
    };
  },
  mounted() {
    if (this.$route.query.tk) {
      this.tk = this.$route.query.tk;
      localStorage.token = this.tk;
    }

    if (localStorage.token) {
      this.tk = localStorage.token;
    }

    this.SelectAppCount();
    this.user_info = { devicesCount: 0, email: "", contact_phones: "", lk: "" };
    this.user_app_data = { devicesActiveDate: "" };
    this.user_app_info = {
      devicesActiveDate: "",
      nextPaymentDate: "",
      planName: "",
      devicesActive: "",
    };
    this.not_select = true;
  },
  methods: {
    smallAlertModalHide() {
      this.smallAlertModal = false;
    },
    CreateTicket(qtype) {
      let data = {};
      data.token = this.tk;
      data.lk = this.user_info.lk;
      data.uid = this.user_info.uid;
      data.app = this.selected_app.app;
      data.app_name = this.selected_app.name;
      data.rezult = this.rezult;
      data.qtype = qtype;

      axios({
        method: "POST",
        url: "https://report.aki1.ru/create_ticket.php",
        timeout: 1000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response);
          if (response.status == 250) {
            this.$router.push({ name: "CuLogin" });
          }
          if (response.status == 200) {
            this.smallAlertModalHide();
          }
          this.rezult = "";
          this.AppClick(this.selected_app);
        })
        .catch((error) => {
          this.notific("danger", "что то пошло не так " + error);
        });
    },
    back() {
      this.not_select = true;
      this.ClientSelected = false;
    },
    ClientsAppClick(app) {
      // this.user_selected = this.user_info.lk + ' ' + this.user_info.uid;
      let data = {};
      data.token = this.tk;
      data.uid = this.user_info.uid;
      data.app = app;
      axios({
        method: "POST",
        url: "https://report.aki1.ru/select_user_data.php",
        timeout: 1000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response);
          if (response.status == 250) {
            this.$router.push({ name: "CuLogin" });
          }
          if (response.status == 200) {
            this.user_app_data = response.data.applog;
            // console.log(this.user_app_data);
            this.user_app_info = response.data.appinfo;
            // console.log(response.data)
          }
        })
        .catch((error) => {
          this.notific("danger", "что то пошло не так " + error);
        });
    },
    SelectAppLog(uid) {
      // this.user_selected = this.user_info.lk + ' ' + this.user_info.uid;
      let data = {};
      data.token = this.tk;
      data.uid = uid;
      data.app = this.selected_app.app;
      axios({
        method: "POST",
        url: "https://report.aki1.ru/select_user_data.php",
        timeout: 1000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response);
          if (response.status == 250) {
            this.$router.push({ name: "CuLogin" });
          }
          if (response.status == 200) {
            this.user_app_data = response.data.applog;
            this.user_app_info = response.data.appinfo;
            // console.log(response.data)
          }
        })
        .catch((error) => {
          this.notific("danger", "что то пошло не так " + error);
        });
    },
    SelectClientInfo(uid) {
      this.user_data = {};
      this.user_info = {
        devicesCount: 0,
        email: "",
        contact_phones: "",
        uid: "",
      };
      this.user_selected = "";
      this.user_app_data = {};
      this.user_app_info = {
        devicesActiveDate: "",
        nextPaymentDate: "",
        planName: "",
        devicesActive: "",
      };
      // this.notific('info', 'Ищу...');
      let data = {};
      data.token = this.tk;
      // data.uid = this.uid;
      data.uid = uid;
      axios({
        method: "POST",
        url: "https://report.aki1.ru/select_client_info.php",
        timeout: 1000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response);
          if (response.status == 250) {
            this.$router.push({ name: "CuLogin" });
          }
          if (response.status == 200) {
            if (response.data.length == 0) {
              this.notific("warning", "Клиент не найден");
            }
            // console.log(response.data)
            this.user_apps = response.data.client_apps;
            this.user_info = response.data.client_info;
          }
        })
        .catch((error) => {
          this.notific("danger", "что то пошло не так " + error);
        });
    },
    SelectAppCount() {
      let data = {};
      if (!this.tk) {
        data.token = localStorage.token;
      } else {
        data.token = this.tk;
      }

      axios({
        method: "POST",
        url: "https://report.aki1.ru/select_app_count.php",
        timeout: 2000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response);
          if (response.status == 250) {
            this.$router.push({ name: "CuLogin" });
          }
          if (response.status == 200) {
            let rd = response.data;
            var sortable = [];
            for (var key in rd) {
              sortable.push([key, rd[key]]);
            }

            sortable.sort(function (a, b) {
              return b[1].all_clients - a[1].all_clients;
            });
            this.apps_status = sortable;
          }
        })
        .catch((error) => {
          this.notific("danger", "что то пошло не так " + error);
        });
    },
    AppClick(app) {
      this.not_select = false;
      this.selected_app = app;
      let data = {};
      data.token = localStorage.token;
      data.uuid = app.app;
      axios({
        method: "POST",
        url: "https://report.aki1.ru/select_app_data.php",
        timeout: 1000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status == 250) {
            this.$router.push({ name: "CuLogin" });
          }
          if (response.status == 200) {
            this.app_data = response.data;
          }
        })
        .catch((error) => {
          this.notific("danger", "что то пошло не так " + error);
        });
    },
    ClientClick(client) {
      this.ClientSelected = true;
      // console.log(client)
      this.SelectClientInfo(client.uid);
      this.SelectAppLog(client.uid);
    },
    notific(type, text) {
      this.$notify({
        message: text,
        icon: "add_alert",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: type,
      });
    },
    getClass: ({ status }) => ({
      "table-success": status === "ACTIVE",
      "table-info": status === "TRIAL",
      "table-danger": status === "INACTIVE",
    }),
  },
};
</script>

<style scoped>
.isActive {
  background: moccasin;
}

.my-card-img {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.my_app_ico {
  border-radius: 15px;
  box-shadow: 0 0 10px #666;
  height: 120px;
  width: 120px;
}
</style>
