<template>
  <div class="md-layout text-center">
    <div
      class="
        md-layout-item
        md-size-33
        md-medium-size-50
        md-small-size-70
        md-xsmall-size-100
      "
    >
      <login-card header-color="green">
        <h4 slot="title" class="title">Log in</h4>
        <!--                <md-button-->
        <!--                        slot="buttons"-->
        <!--                        href="#facebook"-->
        <!--                        class="md-just-icon md-simple md-white"-->
        <!--                >-->
        <!--                    <i class="fab fa-facebook-square"></i>-->
        <!--                </md-button>-->
        <!--                <md-button-->
        <!--                        slot="buttons"-->
        <!--                        href="#twitter"-->
        <!--                        class="md-just-icon md-simple md-white"-->
        <!--                >-->
        <!--                    <i class="fab fa-twitter"></i>-->
        <!--                </md-button>-->
        <!--                <md-button-->
        <!--                        slot="buttons"-->
        <!--                        href="#google"-->
        <!--                        class="md-just-icon md-simple md-white"-->
        <!--                >-->
        <!--                    <i class="fab fa-google-plus-g"></i>-->
        <!--                </md-button>-->
        <!--                <p slot="description" class="description">Or Be Classical</p>-->
        <p class="md-form-group" slot="inputs" v-if="respError">
          <md-icon style="color: red; margin-bottom: 5px">info</md-icon>
          <span style="color: red; font-size: 18px"> Access deny</span>
          <!--                    <md-input v-model="firstname"></md-input>-->
        </p>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email...</label>
          <md-input v-model="email" type="email"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Password...</label>
          <md-input v-model="password"></md-input>
        </md-field>
        <md-button
          slot="footer"
          class="md-simple md-success md-lg"
          v-on:click="sendForm"
        >
          Lets Go
        </md-button>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from "@/components";
const axios = require("axios").default;
export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      firstname: null,
      email: null,
      password: null,
      respError: false,
    };
  },
  watch: {
    email: function () {
      if (!this.validEmail(this.email)) {
      } else {
        localStorage.email = this.email;
      }
    },
  },
  beforeMount() {
    if (localStorage.email) {
      this.email = localStorage.email;
    }
  },
  methods: {
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendForm() {
      this.respError = false;
      let data = {};
      data.email = this.email;
      data.password = this.password;

      axios({
        method: "POST",
        url: "https://report.aki1.ru/login.php",
        timeout: 1000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response.data);
          localStorage.token = response.data;
          this.$router.push({ name: "EvoDashboard" });
        })
        .catch((error) => {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          this.respError = true;
          // console.log(error);
        });
    },
  },
};
</script>

<style></style>
