export default [
  {
    id: 1,
    name: "Noelia O'Kon",
    nickname: "asperiores",
    email: "otho.smitham@example.com",
    salary: "13098.00",
    age: 39,
  },
  {
    id: 2,
    name: "Mr. Enid Von PhD",
    nickname: "alias",
    email: "pollich.cecilia@example.com",
    salary: "35978.00",
    age: 26,
  },
  {
    id: 3,
    name: "Colton Koch",
    nickname: "id",
    email: "little.myrna@example.net",
    salary: "26278.00",
    age: 48,
  },
  {
    id: 4,
    name: "Gregory Vandervort",
    nickname: "vel",
    email: "dock47@example.org",
    salary: "25537.00",
    age: 27,
  },
  {
    id: 5,
    name: "Miss Rahsaan Heaney IV",
    nickname: "qui",
    email: "ugrady@example.org",
    salary: "49003.00",
    age: 21,
  },
  {
    id: 6,
    name: "Ms. Crystel Zemlak IV",
    nickname: "reiciendis",
    email: "amari.rau@example.com",
    salary: "12383.00",
    age: 48,
  },
  {
    id: 7,
    name: "Nona McDermott",
    nickname: "quaerat",
    email: "adrien.baumbach@example.org",
    salary: "18512.00",
    age: 31,
  },
  {
    id: 8,
    name: "Miss Genoveva Murazik V",
    nickname: "rerum",
    email: "ohettinger@example.net",
    salary: "31209.00",
    age: 28,
  },
  {
    id: 9,
    name: "Beulah Huels",
    nickname: "non",
    email: "stefan99@example.com",
    salary: "36920.00",
    age: 53,
  },
  {
    id: 10,
    name: "Zoe Klein",
    nickname: "ex",
    email: "ejacobson@example.org",
    salary: "35616.00",
    age: 27,
  },
  {
    id: 11,
    name: "Vickie Kiehn",
    nickname: "assumenda",
    email: "ayost@example.com",
    salary: "30790.00",
    age: 29,
  },
  {
    id: 12,
    name: "Elwyn Herzog",
    nickname: "praesentium",
    email: "ckassulke@example.net",
    salary: "35785.00",
    age: 27,
  },

];
