<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-50">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon" v-on:click="select_clients">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">Поиск по ЛК</h4>
        </md-card-header>

        <md-card-content>
          <md-field>
            <label>Номер ЛК</label>
            <md-input v-model="lk" type="text"></md-input>
          </md-field>
        </md-card-content>

        <md-card-actions md-alignment="left">
          <md-button class="md-info" v-on:click="Search">Поиск</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <div class="md-layout-item md-size-50">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">Поиск по UID</h4>
        </md-card-header>

        <md-card-content>
          <md-field>
            <label>Evotor UID</label>
            <md-input v-model="uid" type="text"></md-input>
          </md-field>
        </md-card-content>

        <md-card-actions md-alignment="left">
          <md-button class="md-info" v-on:click="Search">Поиск</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <div class="md-layout-item md-size-50">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <div id="client_info" v-if="user_info.devicesCount > 0">
            <div class="md-layout-item md-layout">
              <div class="md-layout-item md-size-100">
                <span><b>Регион </b> {{ user_info.region }}</span>
              </div>
            </div>
            <div class="md-layout-item md-layout">
              <div class="md-layout-item md-size-100">
                <span
                  ><b>Всего устройств </b> {{ user_info.devicesCount }}</span
                >
              </div>
            </div>
            <div class="md-layout-item md-layout">
              <div class="md-layout-item md-size-100">
                <span><b>email</b> {{ user_info.email }}</span>
              </div>
            </div>
            <div class="md-layout-item md-layout">
              <div class="md-layout-item md-size-100">
                <span
                  ><b>Контактный телефон</b>
                  {{ user_info.contact_phones }}</span
                >
              </div>
            </div>
            <div class="md-layout-item md-layout">
              <div class="md-layout-item md-size-50">
                <span><b>Номер ЛК</b> {{ user_info.lk }}</span>
              </div>
            </div>
            <div class="md-layout-item md-layout">
              <div class="md-layout-item md-size-50">
                <span><b>ЭВОТОР ID</b> {{ user_info.uid }}</span>
              </div>
            </div>
          </div>
          <h4 class="title">Приложения клиента</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="user_data" class="table-full-width">
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              :class="getClass(item)"
              v-on:click="row_click(item)"
            >
              <md-table-cell md-label="app">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="status">{{ item.status }}</md-table-cell>
              <md-table-cell md-label="date">{{ item.chdate }}</md-table-cell>
              <!--                            <md-table-cell md-label="phone">{{item.phone }}</md-table-cell>-->
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-50">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Лог по приле</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="user_app_data" class="table-full-width">
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              :class="getClass(item)"
              v-on:click="row_click(item)"
            >
              <md-table-cell md-label="chdate">{{ item.chdate }}</md-table-cell>
              <md-table-cell md-label="status">{{ item.status }}</md-table-cell>
              <md-table-cell md-label="devices">{{
                item.devices
              }}</md-table-cell>
              <md-table-cell md-label="hook">{{ item.hook }}</md-table-cell>
              <md-table-cell md-label="planId">{{ item.planId }}</md-table-cell>
              <md-table-cell md-label="nextBillingDate">{{
                item.nextBillingDate
              }}</md-table-cell>
              <md-table-cell md-label="trialPeriodDuration">{{
                item.trialPeriodDuration
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;

import { StatsCard } from "@/components";
import PaginatedTables from "../../pages/Dashboard/Tables/PaginatedTables";

export default {
  name: "EvoClientReport",
  components: {
    // StatsCard,
    // PaginatedTables,
  },
  data() {
    return {
      lk: "",
      uid: "",
      user_data: {},
      user_info: {},
      user_selected: "",
      user_app_data: {},
    };
  },
  mounted() {},
  methods: {
    send_client_info(info, phone) {
      let data = {};
      data.token = localStorage.token;
      data.lk = phone;
      data.info = JSON.stringify(info);
      axios({
        method: "POST",
        url: "https://report.aki1.ru/recieve_client_info.php",
        timeout: 30000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response);
          if (response.status == 250) {
            this.$router.push({ name: "CuLogin" });
          }
          if (response.status == 200) {
            // console.log(response.data);
          }
        })
        .catch((error) => {
          this.notific("danger", "что то пошло не так " + error);
        });
    },
    select_clients() {
      let data = {};
      data.token = localStorage.token;
      axios({
        method: "POST",
        url: "https://report.aki1.ru/select_clients_list.php",
        timeout: 50000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response);
          if (response.status == 250) {
            this.$router.push({ name: "CuLogin" });
          }
          if (response.status == 200) {
            // this.user_app_data = response.data
            // console.log(response.data);
            this.eh(response.data);
          }
        })
        .catch((error) => {
          this.notific("danger", "что то пошло не так " + error);
        });
    },
    eh(phones) {
      phones.forEach((item, index, array) => {
        let url =
          "https://crmapi.evotor.ru/crm-service/cdp/customer/organisations?lk-phone=" +
          item.phone;
        // console.log(url);

        axios({
          method: "GET",
          url: url,
          timeout: 50000,
          // data: data,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJINHNJQUFBQUFBQUFBRjFTeTBvRFFSRDhsZERuUGN6TXpuUFwvd0l0NEZ3OExCaFhXVVRhYlV3am8zWk40RUNcLyt3aUlLZ1JDXC9vZmVQM0pqdEN1UlcwMVZkWGRNeksxb3U1dTNaTlZYZTZsRFFZOTEyK1ZEUVpXbFRRVGR0bmJzRlZTdUttcXBMZlZXUTFtcEMwVW5KcGdtbE9BSGpTaW1aQ1ZpaERFUlJhbEVNdEE5Q1dzenhZcFhnTHFZQjhoSklySUwwSWJvQnA2QjJTQU4zSXcwbDVBanZrRlFKYTd3c3dpbVFZdUVRUWdGNWk1ckR6R1BzQUNUUmt2Z21jQjZqSWpvOUx1cWd3XC9WMHhHMk9ENGM5YW8zZUFCM2VFRzRLTzFJeVB4aVVSR2FsejhJK0hSSFdOdTFvUFg2ODI0YzhwNHBDMHRyNldGcWpxS0JjMysrTFwvTUdiNFlrM3ZPWHRqTis0NTkzXC80WmVcL1J0RmQ3dVp0cnB1TGcwVmVOZzArOHZsazhNNmZKNDNjelwvaDFQSHdmUUQ4ODc1bmhoWDlHYnNjOXJmOEFlTmJSV1J3REFBQT0iLCJleHAiOjE2MzY0MDg5Njk2MDV9._EhO8XQr6dFpqPo3L2zeivgvlkDb0qWSMNi-PtH6-xg",
          },
        })
          .then((response) => {
            // console.log(response);
            if (response.status == 250) {
              this.$router.push({ name: "CuLogin" });
            }
            if (response.status == 200) {
              // console.log(response);
              this.send_client_info(response.data, item.phone);
            }
          })
          .catch((error) => {
            this.notific("danger", "что то пошло не так " + error);
            // console.log(error);
          });
      });
    },
    row_click(item) {
      this.user_selected = this.user_info.lk + " " + this.user_info.uid;
      let data = {};
      data.token = localStorage.token;
      data.uid = this.user_info.uid;
      data.app = item.app;
      axios({
        method: "POST",
        url: "https://report.aki1.ru/select_user_data.php",
        timeout: 1000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response);
          if (response.status == 250) {
            this.$router.push({ name: "CuLogin" });
          }
          if (response.status == 200) {
            this.user_app_data = response.data;
            // console.log(response.data);
          }
        })
        .catch((error) => {
          this.notific("danger", "что то пошло не так " + error);
        });
    },
    notific(type, text) {
      this.$notify({
        message: text,
        icon: "add_alert",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: type,
      });
    },
    Search() {
      this.notific("info", "Ищу...");
      let data = {};
      data.token = localStorage.token;
      data.uid = this.uid;
      data.lk = this.lk;
      axios({
        method: "POST",
        url: "https://report.aki1.ru/select_client_info.php",
        timeout: 1000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response);
          if (response.status == 250) {
            this.$router.push({ name: "CuLogin" });
          }
          if (response.status == 200) {
            if (response.data.length == 0) {
              this.notific("warning", "Клиент не найден");
            }
            // console.log(response.data);
            this.user_data = response.data.client_apps;
            this.user_info = response.data.client_info;
          }
        })
        .catch((error) => {
          this.notific("danger", "что то пошло не так " + error);
        });
    },
    getClass: ({ status }) => ({
      "table-success": status === "ACTIVE",
      "table-info": status === "TRIAL",
      "table-danger": status === "INACTIVE",
    }),
  },
};
</script>

<style scoped></style>
