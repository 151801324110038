<template>
  <div id="main" class="md-layout">
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <h1 class="title">Welcome!!!</h1>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "Start.vue",
  data() {
    return {
      isActive: 1,
    };
  },
  mounted() {
    // console.log("------------------_++++++++++");
    if (localStorage.token && localStorage.email) {
      let data = {};
      data.token = localStorage.token;
      data.email = localStorage.email;

      axios({
        method: "POST",
        url: "https://report.aki1.ru/login.php",
        timeout: 1000,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response.data);
          // localStorage.token = response.data;
          this.$router.push({ name: "EvoDashboard" });
        })
        .catch((error) => {
          // localStorage.removeItem('token');
          // localStorage.removeItem('email');
          // console.log(error)
          this.$router.push({ name: "CuLogin" });
        });
    } else {
      alert("empty token!!!");
      this.$router.push({ name: "CuLogin" });
    }
  },
  methods: {
    getActiveCollapse(index) {
      return this.isActive == index;
    },
  },
};
</script>

<style scoped></style>
